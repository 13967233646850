import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { removeToken } from "../../../common/utils/cookies";
import { HttpRequest } from "../../../common/utils/request";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import ContributionList from "./components/Contribution";
import { SettingListData } from "./interfaces/index.interface";

export default function SettingPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [settingData, setSettingData] = useState<SettingListData>({
    contribution: {
      amount: {
        period: "",
        value: 0,
      },
      due_date: 5,
    },
  });

  const getSettingContribution = async () => {
    const requestData = await HttpRequest.request<SettingListData[]>({
      url: pathApi.setting.list,
    });

    if (requestData.status !== 200) {
      hideLoading();
      setMessage({
        show: true,
        message: requestData.data.message,
        callback: () => {
          if (requestData.status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      return;
    }

    setSettingData({ ...settingData, ...requestData.data.data });
    hideLoading();
  };

  useEffect(() => {
    (async () => {
      await getSettingContribution();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Pengaturan",
        }}
      />
      <div>
        <ContributionList
          data={{
            amount: settingData.contribution.amount,
            due_date: settingData.contribution.due_date,
          }}
          refreshData={() => getSettingContribution()}
        />
      </div>
      {/* <ModalIpl
        data={modalIplData}
        open={openModalGeneral}
        onClose={() => handleOpenModalGeneral(false)}
      /> */}
    </div>
  );
}
