import {
  Button,
  List,
  ListItem,
  ListItemSuffix,
  Typography,
} from "@material-tailwind/react";
import {
  ContributionItem,
  SettingContributionData,
} from "../interfaces/index.interface";
import { formatCurrency } from "../../../../common/utils/currency";
import { PencilIcon } from "@heroicons/react/24/solid";
import ModalContribution from "./ModalContribution";
import { useEffect, useState } from "react";
import { ModalContributionData } from "../interfaces/modal.interface";
import { HttpRequest } from "../../../../common/utils/request";
import { pathApi, pathUrl } from "../../../../common/constants/path.constant";
import { useNavigate } from "react-router-dom";
import {
  useLoading,
  useMessage,
} from "../../../../components/providers/AppProvider";
import { removeToken } from "../../../../common/utils/cookies";
import { SettingContribution } from "../../../../common/enums/setting.enum";
import { HttpRequestResponse } from "../../../../common/interfaces/httprequest.interface";

interface ContributionListProps {
  data: SettingContributionData;
  refreshData: Function;
}

export default function ContributionList(props: ContributionListProps) {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalContributionData>({
    type: undefined,
  });

  const handleOpenModal = async (type: SettingContribution) => {
    showLoading();
    const requestData = await HttpRequest.request<
      ModalContributionData["amount"] | number
    >({
      url:
        type === SettingContribution.IPL
          ? pathApi.setting.contributionIpl + "/" + props.data.amount?.period
          : pathApi.setting.contributionDue,
    });

    if (requestData.status !== 200) {
      hideLoading();
      setMessage({
        show: true,
        message: requestData.data.message,
        callback: () => {
          if (requestData.status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      return;
    }

    if (typeof requestData.data.data === "number") {
      setModalData({ ...modalData, type, due_date: +requestData.data.data });
    } else {
      setModalData({
        ...modalData,
        type,
        amount: {
          period: requestData.data.data?.period || "",
          items: requestData.data.data?.items || [],
        },
      });
    }
    hideLoading();
    setOpenModal(true);
  };

  return (
    <div className="md:flex md:justity-between md:items-start w-full">
      <div className="mr-auto w-full xs:mb-4 sm:mb-4">
        <Typography variant="lead" className="text-lg">
          Iuran IPL
        </Typography>
        <Typography variant="small" className="text-sm">
          Pengaturan iuran IPL warga
        </Typography>
      </div>

      <List className="p-0 w-full min-w-[60%]">
        <ListItem
          key={1}
          className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 items-start"
          ripple={false}
        >
          <div>
            <Typography variant="small" color="blue-gray" className="text-sm">
              Nominal Iuran IPL
            </Typography>
            <Typography variant="small" color="blue-gray" className="text-sm">
              {formatCurrency(props.data.amount?.value || 0, "Rp ")}
            </Typography>
          </div>
          <ListItemSuffix className="flex flex-row">
            <Button
              variant="text"
              size="sm"
              color="green"
              ripple={true}
              className="px-2 py-1 flex items-center gap-1"
              onClick={() => handleOpenModal(SettingContribution.IPL)}
            >
              <PencilIcon strokeWidth={2} className="h-3 w-3" />
              <Typography
                variant="small"
                className="text-sm capitalize font-normal"
              >
                Atur
              </Typography>
            </Button>
          </ListItemSuffix>
        </ListItem>
        <ListItem
          key={2}
          className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 items-start"
          ripple={false}
        >
          <div>
            <Typography variant="small" color="blue-gray" className="text-sm">
              Batas Waktu Bayar Iuran IPL
            </Typography>
            <Typography variant="small" color="blue-gray" className="text-sm">
              Tanggal {props.data.due_date || "-"}
            </Typography>
          </div>
          <ListItemSuffix>
            <Button
              variant="text"
              size="sm"
              color="green"
              ripple={true}
              className="px-3 py-1 flex items-center gap-1"
              onClick={() => handleOpenModal(SettingContribution.IPL_DUE)}
            >
              <PencilIcon strokeWidth={2} className="h-3 w-3" />
              <Typography
                variant="small"
                className="text-sm capitalize font-normal"
              >
                Atur
              </Typography>
            </Button>
          </ListItemSuffix>
        </ListItem>
      </List>

      <ModalContribution
        data={modalData}
        open={openModal}
        onClose={() => setOpenModal(false)}
        refreshData={() => props.refreshData()}
      />
    </div>
  );
}
