import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Checkbox,
  Typography
} from "@material-tailwind/react";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../../common/enums/httprequest.enum";
import { Status, StatusText } from "../../../common/enums/status.enum";
import { ResponseData } from "../../../common/interfaces/httprequest.interface";
import { HttpRequest } from "../../../common/utils/request";
import { FormInput, FormSelect } from "../../../components/forms";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import { UserFormData } from "./interfaces/form.interface";
import { UserDetailData } from "./interfaces/list.interface";
import { UserValidation } from "./validations/save.validation";

export default function UserForm() {
  let navigate = useNavigate();
  const params = useParams<{ userId: string }>();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<UserFormData>({
    role: "",
    name: "",
    phone: "",
    status: Status.InActive,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const userId: number = +(params.userId || 0);
    const { messages } = UserValidation.save(formData, userId);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    let method = HttpRequestMethodType.POST;
    let url = pathApi.user.list;
    if (userId > 0) {
      method = HttpRequestMethodType.PUT;
      url += `/${userId}`;
    }
    const { status, data } = await HttpRequest.request({
      method,
      url,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.user.list);
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    if (params.userId) {
      (async () => {
        let status = 404;
        let data: ResponseData<UserFormData> = {
          message: "ID user salah",
          data: formData,
        };

        const userId: number = +(params.userId || 0);
        if (userId > 0) {
          const requestData = await HttpRequest.request<UserDetailData>({
            url: `${pathApi.user.list}/${userId}`,
          });

          status = requestData.status;
          data = {
            message: requestData.data.message,
            data: {
              ...formData,
              id: requestData.data.data.id,
              name: requestData.data.data.name,
              role: requestData.data.data.role,
              phone: requestData.data.data.phone,
              status: requestData.data.data.status,
            },
          };
        }

        const isSuccess = [200, 201].includes(status);
        if (!isSuccess) {
          hideLoading();
          setMessage({
            show: true,
            message: data.message,
            callback: () => {
              navigate(pathUrl.user.list);
            },
          });
          return;
        }

        setFormData({
          ...formData,
          role: data.data.role,
          name: data.data.name,
          phone: data.data.phone,
          status: data.data.status,
        });

        hideLoading();
      })();
    } else {
      hideLoading();
    }

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: params.userId ? "Edit Pengguna" : "Tambah Pengguna Baru",
          actionBack: true,
          actionBackUrl: pathUrl.user.list,
        }}
      />
      <div className="page-content">
        <form className="" onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="flex flex-col md:flex-row">
              <div className="basis-full gap-4">
                <div className="mb-8">
                  <Checkbox
                    label={
                      <div>
                        <Typography color="blue-gray" className="font-semibold">
                          {StatusText.Active}
                        </Typography>
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-small"
                        >
                          Klik centang untuk mengaktifkan
                        </Typography>
                      </div>
                    }
                    containerProps={{
                      className: "-mt-5",
                    }}
                    crossOrigin={undefined}
                    checked={!!formData.status}
                    value={Status.Active}
                    onChange={(e) =>
                      handleChange("status", e.target.checked ? 1 : 0)
                    }
                  />
                </div>
                <FormSelect
                  label="Role"
                  classBox="mb-4"
                  value={formData.role}
                  defaultValue={formData.role}
                  onChange={(e: any) => handleChange("role", e)}
                  data={[
                    { id: "Chairman", name: "Ketua RT" },
                    { id: "Treasurer", name: "Bendahara" },
                  ]}
                />
                <FormInput
                  type="text"
                  label="Nama"
                  placeholder="Nama Pengguna"
                  classBox="mb-4"
                  value={formData.name}
                  onChange={(e: any) => handleChange("name", e.target.value)}
                />
                <FormInput
                  type="number"
                  label="Nomor HP"
                  placeholder="08xxxxxxxxx"
                  classBox="mb-8"
                  value={formData.phone}
                  onChange={(e: any) => handleChange("phone", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <Button
              className="flex items-center gap-2"
              variant="gradient"
              size="sm"
              color="blue"
              ripple={true}
              onClick={() => submitForm()}
            >
              <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
