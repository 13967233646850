import React, { ReactNode, createContext, useContext, useState } from "react";
import { MessageProps } from "../../common/interfaces/component.interface";

interface LoadingType {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}
interface MessageType {
  messageData: MessageProps;
  setMessage: (data: MessageProps) => void;
}
interface HeaderType {
  isSidebar: boolean;
  showSidebar: () => void;
  hideSidebar: () => void;
}
interface AppContextType {
  loading: LoadingType | undefined;
  message: MessageType | undefined;
  header: HeaderType | undefined;
}
const AppContext = createContext<AppContextType>({
  loading: undefined,
  message: undefined,
  header: undefined,
});

interface AppProviderProps {
  children: ReactNode;
}
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [isSidebar, setSidebar] = useState(false);
  const [messageData, setMessageData] = useState<MessageProps>({
    show: false,
    message: "",
    callback: () => {},
    title: "",
  });

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);
  const showSidebar = () => setSidebar(true);
  const hideSidebar = () => setSidebar(false);
  const setMessage = (data: MessageProps) =>
    setMessageData((prevData) => {
      return { ...prevData, ...data };
    });

  const contextValue = {
    loading: {
      isLoading,
      showLoading,
      hideLoading,
    },
    message: {
      messageData,
      setMessage,
    },
    header: {
      isSidebar,
      showSidebar,
      hideSidebar,
    },
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useLoading = () => {
  const dataContext = useContext(AppContext);
  if (!dataContext || !dataContext.loading) {
    throw new Error("useLoading must be used within a AppProvider");
  }
  return dataContext.loading;
};

export const useMessage = () => {
  const dataContext = useContext(AppContext);
  if (!dataContext || !dataContext.message) {
    throw new Error("useMessage must be used within a AppProvider");
  }
  return dataContext.message;
};

export const useHeader = () => {
  const dataContext = useContext(AppContext);
  if (!dataContext || !dataContext.header) {
    throw new Error("useHeader must be used within a AppProvider");
  }
  return dataContext.header;
};
