import { CheckIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../common/enums/httprequest.enum";
import { HttpRequest } from "../../common/utils/request";
import { FormInput, FormSelect } from "../../components/forms";
import PageHeader from "../../components/page/PageHeader";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import { ResidentFormData } from "./interfaces/form.interface";
import { ResidentValidation } from "./validations/save.validation";

export default function ResidentForm() {
  let navigate = useNavigate();
  const params = useParams<{ userId: string }>();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<ResidentFormData>({
    house: undefined,
    name: "",
    gender: 1,
    pob: "",
    dob: "",
    religion: "",
    phone: "",
    resident_fee: undefined,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const { messages } = ResidentValidation.save(formData);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.POST,
      url: pathApi.user.list,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.resident.list);
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    hideLoading();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Tambah Warga Baru",
          actionBack: true,
          actionBackUrl: pathUrl.resident.list,
        }}
      />
      <div className="page-content">
        <form className="" onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="basis-full gap-4">
              <FormSelect
                label="Rumah"
                classBox="mb-4"
                value={formData.house?.toString()}
                defaultValue={formData.house?.toString()}
                onChange={(e: any) => handleChange("house", +e)}
                data={[
                  { id: "1", name: "C1 / 1" },
                  { id: "2", name: "C1 / 2" },
                  { id: "3", name: "C1 / 3" },
                  { id: "4", name: "C1 / 4" },
                ]}
              />
              <FormInput
                type="text"
                label="Nama"
                placeholder="Nama"
                classBox="mb-4"
                value={formData.name}
                onChange={(e: any) => handleChange("name", e.target.value)}
              />
              <FormSelect
                label="Jenis Kelamin"
                classBox="mb-4"
                value={formData.gender}
                defaultValue={formData.gender}
                onChange={(e: any) => handleChange("gender", e)}
                data={[
                  { id: "1", name: "Laki-Laki" },
                  { id: "2", name: "Perempuan" },
                ]}
              />
              <FormInput
                type="text"
                label="Tempat Lahir"
                placeholder="Nama Pengguna"
                classBox="mb-4"
                value={formData.pob}
                onChange={(e: any) => handleChange("pob", e.target.value)}
              />
              <FormInput
                type="date"
                label="Tanggal Lahir"
                classBox="mb-4"
                value={formData.dob}
                onChange={(e: any) => handleChange("dob", e.target.value)}
              />
              <FormSelect
                label="Agama"
                classBox="mb-4"
                value={formData.religion}
                defaultValue={formData.religion}
                onChange={(e: any) => handleChange("religion", e)}
                data={[
                  { id: "1", name: "Islam" },
                  { id: "2", name: "Kristen Katholik" },
                  { id: "3", name: "Kristen Protestan" },
                ]}
              />
              <FormInput
                type="number"
                label="Nomor HP"
                placeholder="08xxxxxxxxx"
                classBox="mb-4"
                value={formData.phone}
                onChange={(e: any) => handleChange("phone", e.target.value)}
              />
              <FormInput
                type="number"
                label="Iuran IPL"
                classBox="mb-8"
                value={formData.resident_fee}
                onChange={(e: any) =>
                  handleChange("resident_fee", e.target.value)
                }
              />
            </div>
          </div>
          <div>
            <Button
              className="flex items-center gap-2"
              variant="gradient"
              size="sm"
              color="blue"
              ripple={true}
              onClick={() => submitForm()}
            >
              <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
