export enum ProductCategory {
  AtasanCowok = 1,
  AtasanCewek = 2,
  BawahanCowok = 3,
  BawahanCewek = 4,
  BajuMuslim = 5,
}

export namespace ProductCategory {
  export function toString(category: ProductCategory, type?: string): string {
    if (type === "code") {
      switch (category) {
        case ProductCategory.AtasanCowok:
          return "ATCO";
        case ProductCategory.AtasanCewek:
          return "ATCE";
        case ProductCategory.BawahanCowok:
          return "BACO";
        case ProductCategory.BawahanCewek:
          return "BACE";
        case ProductCategory.BajuMuslim:
          return "BAMU";
        default:
          return "";
      }
    }
    switch (category) {
      case ProductCategory.AtasanCowok:
        return "Atasan Cowok";
      case ProductCategory.AtasanCewek:
        return "Atasan Cewek";
      case ProductCategory.BawahanCowok:
        return "Bawahan Cowok";
      case ProductCategory.BawahanCewek:
        return "Bawahan Cewek";
      case ProductCategory.BajuMuslim:
        return "Baju Muslim";
      default:
        return "";
    }
  }
}
