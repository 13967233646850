import { ResidentFormData } from "../interfaces/form.interface";

export const ResidentValidation = {
  save: (payload: ResidentFormData) => {
    const { house, name, gender, pob, dob, religion, phone } = payload;
    const messages: string[] = [];

    if (house || 0 < 1) messages.push("Rumah wajib diisi.");
    if (name === "") messages.push("Nama wajib diisi.");
    if (![1, 2].includes(gender || 0))
      messages.push("Jenis Kelamin wajib diisi.");
    if (pob === "") messages.push("Tempat Lahir wajib diisi.");
    if (dob === "") messages.push("Tanggal Lahir wajib diisi.");
    if (religion === "") messages.push("Agama wajib diisi.");
    if (phone === "") messages.push("Nomor HP wajib diisi.");

    return { messages };
  },
};
