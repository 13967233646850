import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { MessageDefaultProps } from "../../common/constants/default.constant";
import { useMessage } from "../providers/AppProvider";

export default function Message() {
  const { messageData, setMessage } = useMessage();
  const { show, message, callback } = messageData;

  /**
   * set content message
   */
  const messageContent = () => {
    if (Array.isArray(message) && message.length > 1) {
      return (
        <ul className="mt-2 ml-2 list-inside list-disc">
          {message.map((msg: string) => {
            return <li className="">{msg}</li>;
          })}
        </ul>
      );
    }
    return <span className="mt-2 font-normal">{message}</span>;
  };

  /**
   * close
   */
  const handleOk = () => {
    setMessage(MessageDefaultProps);
    if (typeof callback === "function") {
      callback();
    }
  };

  return (
    <>
      <Dialog
        open={show}
        dismiss={{ enabled: false }}
        animate={{ mount: { y: 0 }, unmount: { y: 0.5 } }}
        size="xs"
        handler={handleOk}
        style={{ zIndex: "9999999" }}
      >
        <DialogBody>{messageContent()}</DialogBody>
        <DialogFooter className="space-x-2 pt-0">
          <Button
            size="sm"
            variant="gradient"
            color="blue-gray"
            onClick={handleOk}
          >
            OK
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
