import { ThemeProvider } from "@material-tailwind/react";
import { AppProvider } from "./components/providers/AppProvider";
import AppRouter from "./router/router";

function App() {
  return (
    <ThemeProvider>
      <AppProvider>
        <AppRouter />
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
