import { colors } from "@material-tailwind/react/types/generic";
import { RoleId } from "../enums/role.enum";

export interface ListMenuProps {
  name: string;
  icon: string;
  color?: colors;
  path: string;
  childs: ListSubMenuProps[];
}
export interface ListSubMenuProps {
  name: string;
  path: string;
}
export const ListMenuAdmin: ListMenuProps[] = [
  {
    name: "Dashboard Pengurus",
    icon: "",
    path: "/",
    childs: [],
  },
  {
    name: "Data Warga",
    icon: "UserGroupIcon",
    color: "light-green",
    path: "/resident",
    childs: [],
  },
  {
    name: "Iuran",
    icon: "ClipboardDocumentListIcon",
    color: "red",
    path: "/resident-fee",
    childs: [
      {
        name: "Iuran IPL",
        path: "/resident-fee/ipl",
      },
      {
        name: "Iuran Lainnya",
        path: "/resident-fee/other",
      },
    ],
  },
  {
    name: "Administrator",
    icon: "Cog8ToothIcon",
    color: "blue",
    path: "/administrator",
    childs: [
      {
        name: "Pengguna",
        path: "/administrator/user",
      },
      {
        name: "Rumah",
        path: "/administrator/house",
      },
      {
        name: "Pengaturan",
        path: "/administrator/setting",
      },
    ],
  },
];
