import { Typography } from "@material-tailwind/react";
import { ReactNode } from "react";

interface DetailInfoProps {
  label: string;
  value: string | ReactNode;
  className?: string | undefined;
  classLabel?: string;
  classValue?: string;
}
export function FormDetail(props: DetailInfoProps) {
  return (
    <div className={"flex flex-col border-b pb-1 gap-0 " + props?.className}>
      <Typography
        variant="small"
        className={"font-semibold " + props?.classLabel}
      >
        {props.label}
      </Typography>
      <Typography variant="small" className={"font-normal " + props?.classValue}>
        {props.value}
      </Typography>
    </div>
  );
}
