import { Outlet } from "react-router-dom";
import Loader from "../loader/Loader";
import Message from "../message/Message";
import { useHeader } from "../providers/AppProvider";

export default function LayoutAuth() {
  const { isSidebar } = useHeader();
  return (
    <>
      <div id="layout-auth" className="min-h-screen">
        <Outlet />
      </div>
      <Loader />
      <Message />
    </>
  );
}
