import axios from "axios";
import {
  HttpRequestHeaderType,
  HttpRequestMethodType,
} from "../enums/httprequest.enum";
import {
  HttpRequestConfigProps,
  HttpRequestParamProps,
  HttpRequestResponse,
  ResponseData,
} from "../interfaces/httprequest.interface";
import { getCookies } from "./cookies";

export const HttpRequest = {
  request: async <T>(
    configs: HttpRequestConfigProps,
    params?: HttpRequestParamProps
  ): Promise<HttpRequestResponse<T>> => {
    const configAxios = HttpRequest.setConfigs(configs);

    return await axios(configAxios)
      .then((response) => {
        const respData: ResponseData<T> = {
          message: response.data.message,
          data: response.data.data,
        };
        if (response.data.metadata) {
          respData["metadata"] = response.data.metadata;
        }
        const resp: HttpRequestResponse<T> = {
          status: 200,
          data: respData,
        };
        return resp;
      })
      .catch((err) => {
        const errResp: HttpRequestResponse<T> = {
          status: err.status,
          data: { message: "Terjadi kesalahan server", data: undefined as T },
        };

        if (err.response !== undefined) {
          errResp.data.message =
            err.response.data.message || err.response.statusText;
          errResp.data.data = err.response.data.result || undefined;
        }

        return errResp;
      });
  },
  setConfigs: (configs: HttpRequestConfigProps) => {
    if (!configs.baseURL) {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "";
      const apiVersion = process.env.API_VERSION || "";
      configs.baseURL = `${apiBaseUrl}${apiVersion}`;
    }
    if (!configs.method) {
      configs.method = HttpRequestMethodType.GET;
    }
    if (!configs.headers) {
      configs.headers = {
        "content-type": HttpRequestHeaderType.ApplicationJson,
      };
    }

    if (!configs.notUseToken) {
      configs.headers["authorization"] = getCookies(
        process.env.REACT_APP_ADMIN_COOKIE_TOKEN as string
      );
    }

    delete configs.notUseToken;

    return configs;
  },
};
