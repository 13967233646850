import Cookies from "js-cookie";
import { UserData } from "../interfaces/login.interface";

export const checkAuth = (): boolean => {
  const token = Cookies.get(process.env.REACT_APP_ADMIN_COOKIE_TOKEN as string);
  return !!token;
};

export const getUser = (): UserData => {
  const user = Cookies.get(process.env.REACT_APP_ADMIN_COOKIE_USER as string);
  const userData = user ? JSON.parse(user) : {};
  return userData;
};

export const setCookies = (key: string, value: string): void => {
  Cookies.set(key, value);
};

export const getCookies = (key: string): string => {
  return Cookies.get(key) as string;
};

export const removeCookies = (key: string): void => {
  Cookies.remove(key);
};

export const removeToken = (): void => {
  removeCookies(process.env.REACT_APP_ADMIN_COOKIE_TOKEN as string);
  removeCookies(process.env.REACT_APP_ADMIN_COOKIE_USER as string);
};
