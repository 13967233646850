import { Typography } from "@material-tailwind/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetUserMenu } from "../../common/utils/function";
import { IconMenu } from "../../components/icon/menu";
import { useLoading } from "../../components/providers/AppProvider";

export default function NavigationPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    hideLoading();
    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 pt-4 pb-8">
      <Typography variant="h6" className="font-normal mb-4">
        Navigasi
      </Typography>
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {GetUserMenu(1).map((menu) => {
          if (menu.path !== "/") {
            return <IconMenu key={menu.path} {...menu} />;
          }
        })}
        {/* <Button
          size="lg"
          variant="gradient"
          color="light-green"
          className="text-center"
          onClick={() => navigate(pathUrl.resident.list)}
        >
          <UserGroupIcon className="h-12 w-12 mx-auto mb-2" color="white" />
          <Typography color="white">Data Warga</Typography>
        </Button>
        <Button
          size="lg"
          variant="gradient"
          color="orange"
          className="text-center"
        >
          <ClipboardDocumentListIcon
            className="h-12 w-12 mx-auto mb-2"
            color="white"
          />
          <Typography color="white">Iuran</Typography>
        </Button>
        <Button
          size="lg"
          variant="gradient"
          color="cyan"
          className="text-center"
        >
          <BanknotesIcon className="h-12 w-12 mx-auto mb-2" color="white" />
          <Typography color="white">Pembayaran</Typography>
        </Button>
        <Button
          size="lg"
          variant="gradient"
          color="light-blue"
          className="text-center"
        >
          <BookOpenIcon className="h-12 w-12 mx-auto mb-2" color="white" />
          <Typography color="white">KAS</Typography>
        </Button>
        <Button
          size="lg"
          variant="gradient"
          color="blue-gray"
          className="text-center"
          onClick={() => navigate(pathUrl.user.list)}
        >
          <Cog8ToothIcon className="h-12 w-12 mx-auto mb-2" color="white" />
          <Typography color="white">Administrator</Typography>
        </Button> */}
      </div>
    </div>
  );
}
