interface BackdropProps {
  classHidden: string;
}

export default function Backdrop(props: BackdropProps) {
  const { classHidden } = props;
  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm z-20 bg-gray-500 bg-opacity-25 transition-opacity ${classHidden}`}
      style={{ zIndex: "999999999" }}
    ></div>
  );
}
