import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  List,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { ReactNode } from "react";
import { StatusFilter } from "../../common/constants/filter.constant";
import { PaginationType } from "../../common/enums/pagination.enum";
import { FormInput, FormSelect } from "../forms";

interface TableListProps {
  loading: boolean;
  search?: boolean;
  defaultSearch?: {
    status?: {
      value: string;
      onChange: Function;
      data?: any[];
    };
    searching?: {
      value: string;
      onChange: Function;
      placeholder?: string;
    };
  };
  customSearch?: ReactNode | undefined;
  header?: ReactNode;
  body?: ReactNode;
  dataFound?: boolean;
  textNotFound?: string;
  pagination?: {
    page: number;
    total_pages: number;
    action: Function;
  };
}
export function TableList(props: TableListProps) {
  const handlePagination = async (type: PaginationType) => {
    await props.pagination?.action(
      type === PaginationType.Previous
        ? PaginationType.Previous
        : PaginationType.Next
    );
  };

  return (
    <div>
      {props.search && (
        <div className="flex flex-col items-center justify-left gap-4 md:flex-row mb-4">
          {props.defaultSearch && props.defaultSearch.status && (
            <FormSelect
              label="Status:"
              size="lg"
              classBox="xs:w-full sm:w-full md:w-auto"
              value={props?.defaultSearch?.status?.value || "-1"}
              onChange={props?.defaultSearch?.status?.onChange}
              data={props.defaultSearch?.status?.data || StatusFilter}
            />
          )}
          {props.defaultSearch && props.defaultSearch.searching && (
            <div className="w-full">
              <FormInput
                size="lg"
                label="Pencarian:"
                value={props?.defaultSearch?.searching?.value}
                onChange={props?.defaultSearch?.searching?.onChange}
                placeholder={props?.defaultSearch?.searching.placeholder}
              />
            </div>
          )}
        </div>
      )}
      {props.customSearch}
      {props.header}
      <div>
        {!props.loading && props.body}
        {props.loading && (
          <div className="flex justify-center align-center px-4 py-8">
            <Spinner />
            <Typography
              variant="small"
              color="blue-gray"
              className="opacity-70 pl-3"
            >
              Loading...
            </Typography>
          </div>
        )}
        {!props.loading && !props.dataFound && (
          <div className="grid p-4 text-center bg-gray-100">
            <div className="grid place-items-center">
              <div className="mb-2">
                <InboxIcon strokeWidth={4} className="h-12 w-12" />
              </div>
              <Typography
                variant="small"
                color="blue-gray"
                className="semibold font-normal opacity-70"
              >
                Data tidak ditemukan
              </Typography>
            </div>
            {props.textNotFound && (
              <Typography
                variant="small"
                color="blue-gray"
                className="opacity-70"
              >
                {props.textNotFound}
              </Typography>
            )}
          </div>
        )}
      </div>

      {!props.loading && props.dataFound && props.pagination && (
        <div className="flex items-center justify-between py-4 px-0">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {props.pagination.page} of {props.pagination.total_pages}
          </Typography>
          <div className="flex gap-2">
            {props.pagination.total_pages > 1 && (
              <>
                <IconButton
                  variant="text"
                  size="sm"
                  ripple={true}
                  className={
                    "rounded-full " +
                    (props.pagination.page === 1 ? "cursor-not-allowed " : "")
                  }
                  disabled={props.pagination.page === 1}
                  onClick={() => handlePagination(PaginationType.Previous)}
                >
                  <ChevronLeftIcon strokeWidth={4} className="h-4 w-4" />
                </IconButton>
                <IconButton
                  variant="text"
                  size="sm"
                  ripple={true}
                  className={
                    "rounded-full " +
                    (props.pagination.page === props.pagination.total_pages
                      ? "cursor-not-allowed "
                      : "")
                  }
                  disabled={
                    props.pagination.page === props.pagination.total_pages
                  }
                  onClick={() => handlePagination(PaginationType.Next)}
                >
                  <ChevronRightIcon strokeWidth={4} className="h-4 w-4" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
