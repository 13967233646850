import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../../common/enums/httprequest.enum";
import { ResponseData } from "../../../common/interfaces/httprequest.interface";
import { HttpRequest } from "../../../common/utils/request";
import { FormInput } from "../../../components/forms";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import { HouseFormData } from "./interfaces/form.interface";
import { HouseDetailData } from "./interfaces/list.interface";
import { HouseValidation } from "./validations/save.validation";

export default function HouseForm() {
  let navigate = useNavigate();
  const params = useParams<{ houseId: string }>();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<HouseFormData>({
    block: "",
    number: undefined,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const houseId: number = +(params.houseId || 0);
    const { messages } = HouseValidation.save(formData);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    let method = HttpRequestMethodType.POST;
    let url = pathApi.house.list;
    if (houseId > 0) {
      method = HttpRequestMethodType.PUT;
      url += `/${houseId}`;
    }
    const { status, data } = await HttpRequest.request({
      method,
      url,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.house.list);
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    if (params.houseId) {
      (async () => {
        let status = 404;
        let data: ResponseData<HouseFormData> = {
          message: "ID rumah salah",
          data: formData,
        };

        const houseId: number = +(params.houseId || 0);
        console.log("houseId", houseId);
        if (houseId > 0) {
          const requestData = await HttpRequest.request<HouseDetailData>({
            url: `${pathApi.house.list}/${houseId}`,
          });

          status = requestData.status;
          data = {
            message: requestData.data.message,
            data: {
              ...formData,
              id: requestData.data.data.id,
              block: requestData.data.data.block,
              number: requestData.data.data.number,
            },
          };
        }

        const isSuccess = [200, 201].includes(status);
        if (!isSuccess) {
          hideLoading();
          setMessage({
            show: true,
            message: data.message,
            callback: () => {
              navigate(pathUrl.house.list);
            },
          });
          return;
        }

        setFormData({
          ...formData,
          id: data.data.id,
          block: data.data.block,
          number: data.data.number,
        });

        hideLoading();
      })();
    } else {
      hideLoading();
    }

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: params.houseId ? "Edit Rumah" : "Tambah Rumah Baru",
          actionBack: true,
          actionBackUrl: pathUrl.house.list,
        }}
      />
      <div className="page-content">
        <form className="" onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="flex flex-col md:flex-row">
              <div className="basis-full gap-4">
                <FormInput
                  type="text"
                  label="Blok"
                  classBox="mb-4"
                  value={formData.block}
                  onChange={(e: any) => handleChange("block", e.target.value)}
                />
                <FormInput
                  type="number"
                  label="Nomor"
                  classBox="mb-8"
                  value={formData.number || ''}
                  onChange={(e: any) => handleChange("number", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <Button
              className="flex items-center gap-2"
              variant="gradient"
              size="sm"
              color="blue"
              ripple={true}
              onClick={() => submitForm()}
            >
              <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
