import { Input } from "@material-tailwind/react";

interface FormInputProps {
  label: string | undefined;
  type?: string;
  placeholder?: string | undefined;
  value?: string | number | undefined;
  defaultValue?: string | number | undefined;
  onChange?: Function | undefined;
  disabled?: boolean;
  classBox?: string;
  classInput?: string;
  size?: string;
}
export function FormInput(props: FormInputProps) {
  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className={props.classBox || ""}>
      {/* {props.label && (
        <Typography
          variant="small"
          className="mb-2 text-left font-semibold !text-gray-900"
        >
          {props.label}
        </Typography>
      )} */}
      <Input
        label={props.label}
        placeholder={props.placeholder}
        type={props.type || "text"}
        crossOrigin={undefined}
        value={props.value}
        onChange={handleChange}
        defaultValue={props.defaultValue}
        size={props.size === "md" ? "md" : "lg"}
        color="blue-gray"
        className={props.classInput}
        // className={
        //   "!border-t-blue-gray-200 focus:!border-t-gray-900 " +
        //   (props.classInput || "")
        // }
        // labelProps={{
        //   className: "before:content-none after:content-none",
        // }}
        disabled={props.disabled}
      />
    </div>
  );
}
