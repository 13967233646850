import { UserFormData } from "../interfaces/form.interface";

export const UserValidation = {
  save: (payload: UserFormData, userId: number) => {
    const { name, phone, status, role } = payload;
    const messages: string[] = [];

    if (role === "") messages.push("Role wajib diisi.");
    if (name === "") messages.push("Nama wajib diisi.");
    if (phone === "") messages.push("Nomor HP wajib diisi.");
    if (![0, 1].includes(+status)) messages.push("Status belum dipilih");

    return { messages };
  },
};
