import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Dialog,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi } from "../../../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../../../common/enums/httprequest.enum";
import { SettingContribution } from "../../../../common/enums/setting.enum";
import { HttpRequest } from "../../../../common/utils/request";
import { FormInput } from "../../../../components/forms";
import { FormDetail } from "../../../../components/forms/detail";
import {
  useLoading,
  useMessage,
} from "../../../../components/providers/AppProvider";
import { ModalContributionData } from "../interfaces/modal.interface";
import { formatCurrency } from "../../../../common/utils/currency";

interface ModalContributionProps {
  data: ModalContributionData;
  open: boolean;
  onClose: Function;
  refreshData: Function;
}

export default function ModalContribution(props: ModalContributionProps) {
  let navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<ModalContributionData>({
    type: undefined,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.PUT,
      url: pathApi.setting.list,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          props.refreshData();
        }
      },
    });
  };

  const closeModal = () => {
    props.onClose();
  };

  const handleChange = (key: string, value: string | number) => {
    if (props.data.type === SettingContribution.IPL) {
      if (key !== "period") {
        const items = [...(formData.amount?.items || [])];
        const index = items.findIndex((i) => i.description === key);
        items[index].amount = +value;
        setFormData({
          type: formData.type,
          due_date: formData.due_date,
          amount: {
            period: formData.amount?.period || "",
            items: items,
          },
        });
      } else {
        setFormData({
          ...formData,
          amount: {
            period: value.toString(),
            items: formData.amount?.items || [],
          },
        });
      }
    } else {
      setFormData({
        ...formData,
        due_date: +value,
      });
    }
  };

  const totalContribution = (): number => {
    const total = (formData.amount?.items || []).reduce(
      (a, b) => a + (b.set ? b.amount : 0),
      0
    );
    return total;
  };

  useEffect(() => {
    if (!props.open) {
      setFormData({
        ...formData,
        type: undefined,
        amount: undefined,
        due_date: undefined,
      });
    } else {
      setFormData({
        ...formData,
        amount: {
          period: props.data.amount?.period || "",
          items: props.data.amount?.items || [],
        },
        due_date: props.data.due_date,
      });
    }
  }, [props.open]);

  return (
    <Dialog
      id="contribute"
      dismiss={{ enabled: false }}
      size={props.data.type === SettingContribution.IPL ? "md" : "xs"}
      open={props.open}
      handler={closeModal}
      className="bg-transparent shadow-none"
      style={{ zIndex: "100" }}
    >
      <Card className="mx-auto w-full">
        <CardBody className="flex flex-col gap-4">
          <Typography variant="lead" color="blue-gray" className="mb-4">
            {props.data?.type === SettingContribution.IPL
              ? "Atur Nominal Iuran IPL"
              : "Atur Batas Waktu Bayar Iuran IPL"}
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              {props.data?.type === SettingContribution.IPL && (
                <>
                  <FormInput
                    type="date"
                    label="Mulai Aktif"
                    size="md"
                    classBox="mb-4"
                    value={formData.amount?.period || ""}
                    onChange={(e: any) =>
                      handleChange("period", e.target.value)
                    }
                  />
                  <Typography variant="paragraph" className="mb-4">
                    Daftar Iuran IPL
                  </Typography>
                  {(formData.amount?.items || []).length > 0
                    ? formData.amount?.items.map((item) => (
                        <div className="flex flex-row gap-2 items-start">
                          <Checkbox
                            crossOrigin={undefined}
                            disabled={!!item.set}
                            checked={!!item.set}
                          />
                          <FormInput
                            key={item.description}
                            label={item.description}
                            size="md"
                            classBox="mb-4 w-full"
                            value={item.amount}
                            onChange={(e: any) =>
                              handleChange(
                                `${item.description}`,
                                e.target.value
                              )
                            }
                            disabled={!item.set}
                          />
                        </div>
                      ))
                    : ""}
                  <FormDetail
                    label="Total"
                    value={formatCurrency(totalContribution(), "Rp ")}
                    className="border-none"
                    classLabel="text-md font-normal"
                    classValue="text-md"
                  />
                </>
              )}
              {props.data?.type === SettingContribution.IPL_DUE && (
                <>
                  <FormInput
                    type="number"
                    label="Maksimal Tanggal"
                    classBox="mb-4"
                    value={formData.due_date || ""}
                    onChange={(e: any) =>
                      handleChange("due_date", e.target.value)
                    }
                  />
                </>
              )}
            </div>
            <div className="flex flex-row gap-2">
              <Button
                className="flex items-center gap-2"
                variant="text"
                size="sm"
                color="blue-gray"
                ripple={true}
                onClick={() => closeModal()}
              >
                <XMarkIcon strokeWidth={2} className="h-5 w-5" /> Tutup
              </Button>
              <Button
                type="submit"
                className="flex items-center gap-2"
                variant="gradient"
                size="sm"
                color="blue"
                ripple={true}
              >
                <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </Dialog>
  );
}
