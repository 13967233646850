import { Spinner } from "@material-tailwind/react";
import Backdrop from "../backdrop/Backdrop";
import { useLoading } from "../providers/AppProvider";

export default function Loader() {
  const { isLoading } = useLoading();
  /**
   * set class hidden
   * for open / hide alert message
   */
  const classHidden = !isLoading ? "hidden" : "";

  return (
    <>
      <Backdrop classHidden={classHidden} />
      <div
        className={`fixed bg-gray-100 rounded-lg top-0 left-1/2 -translate-x-1/2 mt-4 px-8 py-3 flex justify-center m-w-xs ${classHidden}`}
        style={{ zIndex: "999999999" }}
      >
        <Spinner />
        <span className="pl-4">Loading</span>
      </div>
    </>
  );
}
