import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ListItem,
  ListItemSuffix,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageDefault } from "../../../common/constants/default.constant";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { PaginationType } from "../../../common/enums/pagination.enum";
import { SortType } from "../../../common/enums/sort-type.enum";
import { MetadataData } from "../../../common/interfaces/httprequest.interface";
import { removeToken } from "../../../common/utils/cookies";
import { HttpRequest } from "../../../common/utils/request";
import { FormSelect } from "../../../components/forms";
import { TableList } from "../../../components/list";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import {
  ResidentFeeIplListData,
  ResidentFeeIplListQueryParam,
} from "./interfaces/list.interface";
import { FormDetail } from "../../../components/forms/detail";

export default function ResidentFeeIplPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [paramList, setParamList] = useState<ResidentFeeIplListQueryParam>({
    page: PageDefault.Page,
    limit: 10,
    sort_by: "date",
    sort_type: SortType.DESC,
    house: undefined,
    period: "",
  });
  const [listData, setListData] = useState<ResidentFeeIplListData[]>([
    {
      id: 1,
      periode: "Agustus 2024",
      status: 2,
      status_text: "Disetujui",
    },
    {
      id: 1,
      periode: "September 2024",
      status: 1,
      status_text: "Menunggu Persetujuan",
    },
    {
      id: 1,
      periode: "Oktober 2024",
      status: 0,
      status_text: "Belum Bayar",
    },
  ]);
  const [paginationData, setPaginationData] = useState<MetadataData>({
    total_pages: 0,
    total_rows: 0,
  });
  const [loadingList, setLoadingList] = useState<boolean>(true);

  const getList = async (
    params: ResidentFeeIplListQueryParam,
    pageBefore?: number
  ) => {
    const { status, data } = await HttpRequest.request<
      ResidentFeeIplListData[]
    >({
      url: pathApi.residentFeeIpl.list,
      params,
    });

    if (status !== 200) {
      setMessage({
        show: true,
        message: data.message,
        callback: () => {
          if (status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      if (pageBefore) {
        setParamList({ ...paramList, page: pageBefore });
      }
      return;
    }

    setListData(data.data);
    setPaginationData({
      total_pages: data.metadata?.total_pages || 0,
      total_rows: data.metadata?.total_rows || 0,
    });
  };

  const handleFilter = async (key: string, value: number) => {
    showLoading();
    setLoadingList(true);
    setParamList({ ...paramList, [key]: value, page: 1 });
    await getList({ ...paramList, [key]: value, page: 1 });
    setLoadingList(false);
    hideLoading();
  };
  const handlePagination = async (value: PaginationType) => {
    const pagePrev: number = paramList.page - 1;
    const pageNext: number = paramList.page + 1;

    if (value === PaginationType.Previous && pagePrev < 1) {
      return;
    }
    if (
      value === PaginationType.Next &&
      pageNext > paginationData.total_pages
    ) {
      return;
    }

    let pageChoose: number =
      value === PaginationType.Previous ? pagePrev : pageNext;

    showLoading();
    setLoadingList(true);
    setParamList({ ...paramList, page: pageChoose });
    await getList({ ...paramList, page: pageChoose }, paramList.page);
    setLoadingList(false);
    hideLoading();
  };

  useEffect(() => {
    (async () => {
      setLoadingList(true);
      await getList({ ...paramList });
      setLoadingList(false);
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Iuran IPL",
          subtitle: "Daftar iuran IPL warga",
        }}
        // right={{
        //   actionAdd: true,
        //   actionAddTitle: "Baru",
        //   actionAddUrl: pathUrl.resident.new,
        // }}
      />

      {/* Page List */}
      <TableList
        loading={loadingList}
        search={false}
        customSearch={
          <div className="flex flex-col items-center justify-left gap-4 md:flex-row mb-4">
            <FormSelect
              label="Rumah:"
              size="lg"
              classBox="xs:w-full sm:w-full md:w-auto"
              value={"-1"}
              defaultValue={"-1"}
              data={[
                { id: -1, name: "Semua" },
                { id: 1, name: "C1 / 23" },
              ]}
            />
            <FormSelect
              label="Periode:"
              size="lg"
              classBox="xs:w-full sm:w-full md:w-auto"
              value={"-1"}
              defaultValue={"-1"}
              data={[
                { id: -1, name: "Semua" },
                { id: 1, name: "C1 / 23" },
              ]}
            />
            <Button
              className="flex items-center gap-2 xs:w-full xs:place-content-center"
              variant="gradient"
              color="blue"
              ripple={true}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> Cari
            </Button>
          </div>
        }
        header={
          <div className="mb-8">
            <div className="grid sm:grid-cols-4 gap-2">
              <FormDetail
                label="Nama:"
                value="Irsyad Syahruddin"
                className="border-none"
              />
              <FormDetail
                label="Tanggal Bergabung:"
                value="01 Agustus 2024"
                className="border-none"
              />
            </div>
          </div>
        }
        dataFound={!!listData.length}
        textNotFound="Cari nama pengguna atau ganti filter"
        pagination={{
          page: paramList.page,
          total_pages: paginationData.total_pages,
          action: handlePagination,
        }}
        body={listData.map((item: ResidentFeeIplListData, index: number) => {
          const isLast = index === listData.length - 1;
          const tdClasses =
            "px-4 py-2 border-b border-blue-gray-50" +
            (isLast ? " border-b-0" : "");
          const bgColorList =
            item.status === 2
              ? "bg-teal-500 hover:bg-teal-700"
              : item.status === 1
              ? "bg-orange-500 hover:bg-orange-700"
              : "bg-red-500 hover:bg-read-700";
          return (
            <ListItem
              key={index}
              className={"mb-2 py-2 " + bgColorList}
              ripple={false}
            >
              <div>
                <Typography
                  variant="paragraph"
                  color="white"
                  className="font-normal"
                >
                  {item.periode}
                </Typography>
              </div>
              <ListItemSuffix>
                {item.status === 2 ? (
                  <Button
                    variant="gradient"
                    size="sm"
                    color="white"
                    className="px-2 py-1 flex items-center gap-1"
                    onClick={() =>
                      navigate(pathUrl.resident.list + "/" + item.id)
                    }
                  >
                    <EyeIcon strokeWidth={2} className="h-3 w-3" />
                    <Typography variant="small" className="text-xs">
                      Detail
                    </Typography>
                  </Button>
                ) : (
                  <Typography
                    variant="small"
                    className="text-xs text-white font-normal"
                  >
                    {item.status_text}
                  </Typography>
                )}
              </ListItemSuffix>
            </ListItem>
          );
        })}
      />
    </div>
  );
}
