import { HouseFormData } from "../interfaces/form.interface";

export const HouseValidation = {
  save: (payload: HouseFormData) => {
    const { block, number } = payload;
    const messages: string[] = [];

    if (block === "") messages.push("Blok wajib diisi.");
    if ((number || 0) < 1) messages.push("Nomor wajib diisi.");

    return { messages };
  },
};
