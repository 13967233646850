import { Button, Typography } from "@material-tailwind/react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { FormInput } from "../../components/forms";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import AuthHeader from "./components/Header";
import { useEffect, useState } from "react";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import { HttpRequestMethodType } from "../../common/enums/httprequest.enum";
import { HttpRequest } from "../../common/utils/request";
import { LoginVerifyResponse } from "../../common/interfaces/login.interface";
import { setCookies } from "../../common/utils/cookies";
import { SetTimerOtp } from "../../common/utils/function";
import moment from "moment";
import { LoginResponse } from "./interfaces/login.interface";

export default function AuthLoginVerifyPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ phone: string; verify_expired: string }>();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [verifyCode, setVerifyCode] = useState<string>("");
  const [verifyTime, setVerifyTime] = useState<number>(
    SetTimerOtp(+(params.verify_expired || Date.now()))
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoading();

    /**
     * validasi email dan password
     * */
    if (verifyCode === "") {
      hideLoading();
      setMessage({
        show: true,
        message: ["Kode verifikasi wajib diisi."],
      });
      return;
    }

    const { phone } = params;

    /** request verify login */
    const reqVerifyLogin = await HttpRequest.request<LoginVerifyResponse>({
      url: pathApi.auth.verifyLogin,
      method: HttpRequestMethodType.POST,
      data: {
        verify_code: verifyCode,
        phone,
      },
      notUseToken: true,
    });

    /** jika response error */
    if (![200, 201].includes(reqVerifyLogin.status)) {
      hideLoading();
      setMessage({
        show: true,
        message: reqVerifyLogin.data.message,
      });
      return;
    }

    /**
     * set cookies
     * - token
     * - user
     */
    const tokenApp: string = reqVerifyLogin.data.data?.token;
    setCookies(process.env.REACT_APP_ADMIN_COOKIE_TOKEN as string, tokenApp);
    setCookies(
      process.env.REACT_APP_ADMIN_COOKIE_USER as string,
      JSON.stringify(reqVerifyLogin.data.data?.user)
    );

    /**
     * redirect navigation page
     */
    navigate(pathUrl.navigation);
  };

  const resendCode = async () => {
    showLoading()
    /** request resend code */
    const reqLogin = await HttpRequest.request<LoginResponse>({
      url: pathApi.auth.login,
      method: HttpRequestMethodType.POST,
      data: {
        phone: params.phone,
      },
      notUseToken: true,
    });

    hideLoading();

    /** jika response error */
    if (![200, 201].includes(reqLogin.status)) {
      setMessage({
        show: true,
        message: reqLogin.data.message,
      });
      return;
    }

    /**
     * set expire time
     */
    const pathUrl = location.pathname.split("/");
    pathUrl[pathUrl.length - 1] = reqLogin.data.data.expiry_time.toString();
    navigate(pathUrl.join("/"), { replace: true });
    setVerifyTime(SetTimerOtp(reqLogin.data.data.expiry_time));
  };

  useEffect(() => {
    hideLoading();
    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * time verify code
   */
  useEffect(() => {
    console.log("verifyTime", verifyTime);
    if (verifyTime > 0) {
      const timeoutId = setTimeout(() => {
        setVerifyTime((prevTime) => prevTime - 1);
      }, 1000);

      // Cleanup timeout if component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [verifyTime]);

  return (
    <div className="lg:min-h-screen lg:h-full lg:flex lg:justify-between">
      <AuthHeader page="verify" />
      <div className="relative flex justify-center items-center lg:min-h-screen lg:h-full lg:w-5/12 sm:pt-16 xs:pt-16">
        <div className="absolute top-6 left-6 ">
          <Button
            onClick={() => navigate(pathUrl.auth.login, {replace: true})}
            className="flex items-base gap-2 px-4"
            size="sm"
            variant="filled"
            color="white"
          >
            <ArrowLeftIcon className="h-4 w-4" /> Kembali
          </Button>
        </div>
        {verifyTime > 0 ? (
          <div className="w-full p-6">
            <div className="mb-10">
              <Typography variant="paragraph">
                Masukkan kode verifikasi yang telah dikirim ke nomor telegram
                anda
              </Typography>
            </div>
            <form className="space-y-6 mb-6" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-6">
                <FormInput
                  label="Kode Verifikasi"
                  type="number"
                  placeholder="******"
                    onChange={(e: any) => setVerifyCode(e.target.value)}
                />
                <Button type="submit" size="md" color="blue" ripple={true}>
                  Kirim
                </Button>
              </div>
            </form>
            <div className="mb-4">
              <Typography variant="small">
                Masukkan kode OTP dalam waktu{" "}
                <span className="font-semibold">{verifyTime}</span> detik
              </Typography>
            </div>
            <div>
              <Typography variant="small">
                Tidak dapat kode verifikasi?
              </Typography>
              <Typography
                variant="paragraph"
                color="blue"
                className="font-normal text-xs cursor-pointer"
              >
                Kirim Ulang
              </Typography>
            </div>
          </div>
        ) : (
          <div className="w-full px-4 pt-8 text-center">
            <Typography variant="h6" className="mb-4 font-normal">
              Waktu verifikasi kode OTP habis
            </Typography>
            <Typography
              variant="paragraph"
              color="blue"
              className="font-normal cursor-pointer"
              onClick={() => resendCode()}
            >
              Kirim Ulang
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
