import { MessageProps } from "../interfaces/component.interface";

export const MessageDefaultProps: MessageProps = {
  show: false,
  message: "",
  callback: () => {},
  title: "",
  type: undefined,
};

export const TableHeaders = {
  workspace: [
    {
      title: "Nama",
      minWidth: "200px",
    },
    // {
    //   title: "Alamat",
    //   // minWidth: "300px",
    //   width: "300px",
    // },
    {
      title: "Status",
      // minWidth: "150px",
      width: "150px",
    },
    {
      title: "",
      // minWidth: "150px",
      width: "75px",
    },
  ],
  user: [
    {
      title: "Nama",
      minWidth: "200px",
    },
    {
      title: "Email",
      // minWidth: "300px",
      width: "300px",
    },
    {
      title: "Role",
      // minWidth: "150px",
      width: "150px",
    },
    // {
    //   title: "Area Kerja",
    //   // minWidth: "150px",
    //   width: "150px",
    // },
    {
      title: "Status",
      // minWidth: "150px",
      width: "150px",
    },
    {
      title: "",
      // minWidth: "150px",
      width: "150px",
    },
  ],
  subscription: [
    { title: "Nama", width: "", minWidth: "" },
    { title: "Paket", width: "150px", minWidth: "" },
    { title: "", width: "75px", minWidth: "" },
  ],
};

export const DropdownWorkplaceDefault = [{ id: -1, name: "Pilih" }];

export const PageDefault = {
  Page: 1,
  Limit: 10,
};
