export const pathApi = {
  auth: {
    login: "/auth/login",
    verifyLogin: "/auth/verify-login",
  },
  user: {
    list: "/users",
  },
  house: {
    list: "/houses",
  },
  setting: {
    list: "/settings",
    contributionIpl: "/settings/contributions/ipl",
    contributionDue: "/settings/contributions/duedate",
  },
  resident: {
    list: "/residents",
  },
  residentFeeIpl: {
    list: "/resident-fees/ipl",
  },
  members: {
    list: "/members",
  },
  subscriptions: {
    list: "/subscriptions",
    confirmation: "/subscriptions/confirmation",
    rejected: "/subscriptions/rejected",
  },
  providers: {
    list: "/providers",
    purchase: "/providers/purchases",
  },
};

export const pathUrl = {
  navigation: '/',
  auth: {
    login: "/auth/login",
    verifyLogin: "/auth/verify-login",
  },
  resident: {
    list: "/resident",
    new: "/resident/new",
  },
  user: {
    list: "/administrator/user",
    new: "/administrator/user/new",
  },
  house: {
    list: "/administrator/house",
    new: "/administrator/house/new",
  },
  setting: {
    list: "/administrator/setting",
  },
  member: {
    list: "/member",
    new: "/member/new",
  },
  subscription: {
    list: "/subscription",
    register: "/subscription/register",
    confirmation: "/subscription/confirmation",
  },
  provider: {
    list: "/provider",
    new: "/provider/new",
  },
  device: {
    list: "/device",
    new: "/device/new",
  },
};
