export enum RoleId {
  SuperAdmin = 1,
  HeadOffice = 2,
  AdminArea = 3,
}

export enum RoleText {
  SuperAdmin = "Super Admin",
  HeadOffice = "Head Office",
  AdminArea = "Admin Area",
}

export namespace RoleId {
  export function toString(role_id: RoleId): string {
    switch (role_id) {
      case RoleId.SuperAdmin:
        return RoleText.SuperAdmin;
      case RoleId.HeadOffice:
        return RoleText.HeadOffice;
      case RoleId.AdminArea:
        return RoleText.AdminArea;
      default:
        return "";
    }
  }
}
