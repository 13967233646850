import { ProductCategory } from "../enums/category.enum";
import { RoleId, RoleText } from "../enums/role.enum";
import { Status, StatusText } from "../enums/status.enum";

export const StatusFilter = [
  {
    name: "Semua",
    id: "-1",
  },
  {
    name: StatusText.Active,
    id: Status.Active,
  },
  {
    name: StatusText.InActive,
    id: Status.InActive,
  },
];

export const ProductCategoryFilter = [
  {
    name: "Pilih Kategori",
    id: -1,
  },
  {
    name: ProductCategory.toString(ProductCategory.AtasanCowok),
    id: ProductCategory.AtasanCowok,
  },
  {
    name: ProductCategory.toString(ProductCategory.AtasanCewek),
    id: ProductCategory.AtasanCewek,
  },
  {
    name: ProductCategory.toString(ProductCategory.BawahanCowok),
    id: ProductCategory.BawahanCowok,
  },
  {
    name: ProductCategory.toString(ProductCategory.BawahanCewek),
    id: ProductCategory.BawahanCewek,
  },
  {
    name: ProductCategory.toString(ProductCategory.BajuMuslim),
    id: ProductCategory.BajuMuslim,
  },
];

export const RoleFilter = [
  {
    name: "Semua",
    id: "-1",
  },
  {
    name: RoleText.HeadOffice,
    id: RoleId.HeadOffice,
  },
  {
    name: RoleText.AdminArea,
    id: RoleId.AdminArea,
  },
];
