import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { PageHeaderProps } from "../../common/interfaces/component.interface";
import { Button, Typography } from "@material-tailwind/react";

export default function PageHeader(props: PageHeaderProps) {
  const { left, right } = props;
  const { title, subtitle, actionBack, actionBackUrl } = left;
  const { actionAdd, actionAddUrl, actionAddTitle, children } = right || {};

  const navigate = useNavigate();

  const handleActionBack = () => {
    const url = actionBackUrl || "";
    navigate(url);
  };

  const handleActionAdd = () => {
    const url = actionAddUrl || "";
    navigate(url);
  };

  return (
    <div className="page-header relative sm:flex sm:items-center sm:mb-8 sm:justify-between xs:grid xs:mb-8">
      <div className="page-title flex justify-start items-center xs:mb-4">
        {actionBack && (
          <div>
            <Button
              onClick={handleActionBack}
              className="mr-2 cursor-pointer rounded-full p-2"
              // size="sm"
              variant="gradient"
              color="white"
              ripple={true}
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </Button>
          </div>
        )}
        <div>
          <Typography variant="lead" color="blue-gray" className="mb-0 pb-0 text-lg">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="small" color="gray" className="mt-0 pt-0 text-xs">
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      {children
        ? children
        : actionAdd && (
            <div className="page-action">
              <Button
                variant="gradient"
                size="sm"
                color="blue"
                ripple={true}
                className="flex items-center gap-2"
                onClick={handleActionAdd}
              >
                <PlusIcon strokeWidth={2} className="h-5 w-5" />
                {actionAddTitle}
              </Button>
            </div>
          )}
    </div>
  );
}
