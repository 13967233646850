import {
  ArrowRightStartOnRectangleIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { pathUrl } from "../../common/constants/path.constant";
import { getUser, removeToken } from "../../common/utils/cookies";
import { GetPageTitle, GetUserSubmenu } from "../../common/utils/function";
import { useHeader } from "../providers/AppProvider";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = getUser();
  const { isSidebar, showSidebar, hideSidebar } = useHeader();

  const doLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Anda yakin ingin keluar?")) {
      removeToken();
      navigate(pathUrl.auth.login);
    }
  };

  return (
    <>
      <div className="w-full bg-blue-700">
        <div className="w-full lg:w-9/12 mx-auto">
          <div className="flex justify-between items-center px-4 py-4">
            <div className="flex items-center gap-2">
              {location.pathname !== "/" && (
                <Squares2X2Icon
                  className="h-6 w-6 cursor-pointer"
                  color="white"
                  onClick={() => navigate(pathUrl.navigation)}
                />
              )}
              <Typography variant="lead" color="white" className="text-lg">
                {GetPageTitle(location.pathname)}
              </Typography>
            </div>
            <div className="flex items-center">
              <Typography variant="paragraph" color="white">
                {userData.name}
              </Typography>

              <ArrowRightStartOnRectangleIcon
                className="h-6 w-6 ml-2 cursor-pointer"
                color="white"
                onClick={() => doLogout()}
              />
            </div>
          </div>
        </div>
      </div>
      {GetUserSubmenu(1, location.pathname).length > 1 && (
        <div className="w-full bg-blue-gray-50">
          <div className="w-full lg:w-9/12 mx-auto">
            <div className="">
              {/* <hr className="mb-3 mt-6 hidden w-full lg:block" /> */}
              <ul className="flex flex-row gap-2 min-w-full overflow-x-auto px-4 py-2">
                {GetUserSubmenu(1, location.pathname).map((menu) => (
                  <Typography
                    key={menu.path}
                    as="li"
                    variant="small"
                    color="black"
                    className={
                      "px-4 py-1 min-w-32 w-32 text-sm text-center font-medium rounded-md hover:bg-blue-gray-300 cursor-pointer " +
                      (location.pathname.indexOf(menu.path) === 0
                        ? "bg-blue-gray-200"
                        : "bg-blue-gray-50")
                    }
                    onClick={() => navigate(menu.path)}
                  >
                    {menu.name}
                  </Typography>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
