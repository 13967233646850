import { Button, Typography } from "@material-tailwind/react";
import { FormInput } from "../../components/forms";
import AuthHeader from "./components/Header";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import { useEffect, useState } from "react";
import { HttpRequest } from "../../common/utils/request";
import { LoginResponse } from "./interfaces/login.interface";
import { HttpRequestMethodType } from "../../common/enums/httprequest.enum";

export default function AuthLoginPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [phone, setPhone] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoading();

    /**
     * validasi email dan password
     * */
    if (phone === "" || phone.length < 11) {
      hideLoading();
      setMessage({
        show: true,
        message: ["Nomor HP wajib diisi dengan lengkap dan minimal 11 nomor"],
      });
      return;
    }

    /** request login */
    const reqLogin = await HttpRequest.request<LoginResponse>({
      url: pathApi.auth.login,
      method: HttpRequestMethodType.POST,
      data: {
        phone,
      },
      notUseToken: true,
    });

    /** jika response error */
    if (![200, 201].includes(reqLogin.status)) {
      hideLoading();
      setMessage({
        show: true,
        message: reqLogin.data.message,
      });
      return;
    }

    /**
     * redirect verify login
     */
    navigate(
      `${pathUrl.auth.verifyLogin}/${phone}/${reqLogin.data.data.expiry_time}`
    );
  };

  useEffect(() => {
    hideLoading();
    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="lg:min-h-screen lg:h-full lg:flex lg:justify-between">
      <AuthHeader page="login" />
      <div className="flex justify-center items-center lg:min-h-screen lg:h-full lg:w-5/12 sm:pt-8 xs:pt-8">
        <div className="w-full px-6">
          <div className="mb-8">
            <Typography variant="h3" className="xs:text-2xl mb-2">Login</Typography>
            <Typography variant="paragraph">
              Silahkan masukkan nomor HP anda untuk melanjutkan
            </Typography>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-6">
              <FormInput
                label="Nomor HP"
                type="number"
                placeholder="08xxxxxxxxx"
                onChange={(e: any) => setPhone(e.target.value)}
              />
              <Button type="submit" size="md" color="blue" ripple={true}>
                Masuk
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
