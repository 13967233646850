import { Typography } from "@material-tailwind/react";

interface AuthHeaderProps {
  page: "login" | "verify";
}
export default function AuthHeader(props: AuthHeaderProps) {
  return (
    <>
      <div className="flex lg:justify-center lg:items-center bg-blue-700 lg:min-h-screen lg:h-full w-full">
        <div
          className={
            "lg:w-8/12 lg:-mt-10 px-4 " +
            (props.page === "verify" ? "py-4" : "py-12")
          }
        >
          {props.page === "login" ? (
            <>
              <Typography variant="h3" color="white" className="xs:text-xl">
                Selamat Datang
              </Typography>
              <Typography variant="h6" color="white" className="xs:text-sm font-normal">
                Dashboard Pesona Ciputih Blok C
              </Typography>
            </>
          ) : (
            <Typography variant="h3" color="white" className="xs:text-lg sm:text-lg lg:text-3xl">
              Verifikasi OTP
            </Typography>
          )}
        </div>
      </div>
    </>
  );
}
